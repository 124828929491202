<template>
  <div class="flex-items-center">

    <div v-if="this.$route.params.lastname">
      <h2>
      {{this.$route.params.lastname}}{{ this.$t('lastname.title') }}</h2>
    </div>
    <div v-if="this.lastname.pinyinArray && this.lastname.pinyinArray.length > 0">
      <label class="label-title">
        {{ $t('lastname.pinyin') }}:</label><label class="label-value">{{this.lastname.pinyinArray.join(", ")}}</label>
    </div>
    <div v-if="this.lastname.fan">
      <label class="label-title">{{ $t('lastname.fanti') }}:</label><label class="label-value">{{this.lastname.fan}}</label>
    </div>
    <div v-if="this.lastname.yiti && this.lastname.yiti.length > 0">
      <label class="label-title">{{ $t('lastname.yiti') }}:</label><label class="label-value">{{this.lastname.yiti.join(", ")}}</label>
    </div>
    <div v-if="this.lastname.radicals && this.lastname.radicals.length > 0">
      <label class="label-title">{{ $t('lastname.radicals') }}:</label><label class="label-value">{{this.lastname.radicals.join(", ")}}</label>
    </div>
    <div v-if="this.lastname.strokes && this.lastname.strokes.length > 0">
      <label class="label-title">{{ $t('lastname.strokes') }}:</label><label class="label-value">{{this.lastname.strokes.join(", ")}}</label>
    </div>
    <div v-if="this.lastname.wuxing && this.lastname.wuxing.length > 0">
      <label class="label-title">{{ $t('lastname.wuxing') }}:</label><label class="label-value">{{this.lastname.wuxing.join(", ")}}</label>
    </div>
    <div v-if="this.lastname.englishArray && this.lastname.englishArray.length > 0">
      <label class="label-title">{{ $t('lastname.english') }}:</label><label class="label-value">{{this.lastname.englishArray.join(", ")}}</label>
      <br/>
      <label class="label-title">{{ $t('lastname.englishNote') }}:</label>
    </div>
    <hr/>
    <label class="label-title" style="white-space: pre-wrap;">{{this.lastname.desc}}</label>
    <hr/>
  </div>
</template>

<script>
import { setMeta, setTitle } from '../../utils/setMeta'
import axios from 'axios'
import { useStore } from 'vuex'
import { computed } from 'vue'
const httpHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'api-version': 110,
  Authorization:
      '$X3t8&Rmm8+n0IATWO125^KK78aQ*p4&I2Y&s_9O8TGnH5g#SVb9l54&?75USS3u'
}
export default {
  name: 'LastName',
  created () {
    setTitle(this.$route.params.lastname + this.$t('lastname.title'))
    setMeta('keywords', 'keywords', this.$route.params.lastname + this.$t('lastname.keywords'))
    setMeta('description', 'description', this.$route.params.lastname + this.$t('lastname.description') + this.$t('lastname.pinyin') + ', ' +
      this.$t('lastname.yiti') + ', ' +
      this.$t('lastname.fanti') + ', ' +
      this.$t('lastname.radicals') + ', ' +
      this.$t('lastname.strokes') + ', ' +
      this.$t('lastname.wuxing') + ', ' +
      this.$t('lastname.english'))
    this.fetchTheLastName()
  },
  setup () {
    const store = useStore()
    return {
      lastname: computed(() => store.state.theLastName)
    }
  },
  methods: {
    fetchTheLastName () {
      axios.get(process.env.VUE_APP_BASE_URL + '/lastname/' + encodeURIComponent(this.$route.params.lastname), { headers: httpHeader })
        .then((res) => {
          if (res.status === 200) {
            this.$store.commit('updateTheLastName', res.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
  .flex-items-center {
    align-items: center !important;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI Variable,Segoe UI,system-ui,ui-sans-serif,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    line-height: 1.5;
    padding-bottom: 0.571rem !important;
    padding-top: 0.571rem !important;
    text-align: left;
  }
  .title {
    font-size: 1.714rem;
    font-weight: 400;
    margin-bottom: 0.571rem;
  }
</style>
