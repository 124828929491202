<template>
  <div class="flex-items-center">
    <div >
      <ul class="header-ul">
        <li class="header-li">
          <img alt="{{$t('common.site-name')}}" src="../assets/起名_36x36.png" style="width: 1.286rem;">
        </li>
        <li class="header-li">
          <router-link :to="{ name: 'HomePage'}">
            {{ $t('header.home') }}
          </router-link>
        </li>
        <li class="header-li">
          <router-link :to="{ name: 'AnalyzePage'}">
            {{ $t('header.analyze') }}
          </router-link>
        </li>
        <li class="header-li">
          <router-link :to="{ name: 'DownloadPage'}">
            {{ $t('header.download') }}
          </router-link>
        </li>
        <li class="header-li">
          <router-link :to="{ name: 'AboutUs'}">
            {{ $t('header.aboutus') }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderPage',
  props: {
    msg: String
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import "../assets/css/Header.css";
  @import "../assets/css/Shared.css";
</style>
