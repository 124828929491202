<template>
  <div class="flex-items-center" style="display: flow;">
    <p class="title">{{ $t('zodiac.title1') }}</p>
    <ul>
      <li>
        <router-link :title="$t('shu.title')" :to="{ name: 'Zodiac', params: { zodiac: 'shu' }}">
          {{ $t('shu.title') }}
        </router-link>
      </li>
      <li>
        <router-link :title="$t('niu.title')" :to="{ name: 'Zodiac', params: { zodiac: 'niu' }}">
          {{ $t('niu.title') }}
        </router-link>
      </li>
      <li>
        <router-link :title="$t('hu.title')" :to="{ name: 'Zodiac', params: { zodiac: 'hu' }}">
          {{ $t('hu.title') }}
        </router-link>
      </li>
      <li>
        <router-link :title="$t('tu.title')" :to="{ name: 'Zodiac', params: { zodiac: 'tu' }}">
          {{ $t('tu.title') }}
        </router-link>
      </li>

      <li>
        <router-link :title="$t('long.title')" :to="{ name: 'Zodiac', params: { zodiac: 'long' }}">
          {{ $t('long.title') }}
        </router-link>
      </li>

      <li>
        <router-link :title="$t('she.title')" :to="{ name: 'Zodiac', params: { zodiac: 'she' }}">
          {{ $t('she.title') }}
        </router-link>
      </li>

      <li>
        <router-link :title="$t('ma.title')" :to="{ name: 'Zodiac', params: { zodiac: 'ma' }}">
          {{ $t('ma.title') }}
        </router-link>
      </li>

      <li>
        <router-link :title="$t('yang.title')" :to="{ name: 'Zodiac', params: { zodiac: 'yang' }}">
          {{ $t('yang.title') }}
        </router-link>
      </li>
      <li>
        <router-link :title="$t('hou.title')" :to="{ name: 'Zodiac', params: { zodiac: 'hou' }}">
          {{ $t('hou.title') }}
        </router-link>
      </li>

      <li>
        <router-link :title="$t('ji.title')" :to="{ name: 'Zodiac', params: { zodiac: 'ji' }}">
          {{ $t('ji.title') }}
        </router-link>
      </li>

      <li>
        <router-link :title="$t('gou.title')" :to="{ name: 'Zodiac', params: { zodiac: 'gou' }}">
          {{ $t('gou.title') }}
        </router-link>
      </li>

      <li>
        <router-link :title="$t('zhu.title')" :to="{ name: 'Zodiac', params: { zodiac: 'zhu' }}">
          {{ $t('zhu.title') }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { setMeta, setTitle } from '../../utils/setMeta'

export default {
  name: 'ZodiacsPage',
  created () {
    setTitle(this.$t('zodiac.title'))
    setMeta('keywords', 'keywords', this.$t('zodiac.keywords'))
    setMeta('description', 'description', this.$t('zodiac.description'))
  }
}
</script>
<style scoped>
  @import "../../assets/css/Shared.css";
</style>
