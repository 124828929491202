<template>
  <div class="flex-items-center">
    <img :title="$t('common.site-name')" alt="{{$t('common.site-name')}}" src="../assets/起名.png" style="width: 100px;">
    <p><a href="https://xuanzong.info/download/xuan-zong-qi-ming.apk" target="_blank">{{ $t('download.android') }}</a></p>
    <p><a href="https://apps.apple.com/us/app/%E7%8E%84%E5%AE%97%E8%B5%B7%E5%90%8D/id1546625085" target="_blank">{{ $t('download.ios') }}</a></p>

    <div style="margin-bottom: 100px">
      <img :title="$t('download.android')" alt="{{ $t('download.android') }}" src="../assets/android-qr.png" style="width: 100px;">
      <p style="margin-top: 0px">{{ $t('download.scanqr') }}{{ $t('download.android') }}</p>
    </div>
    <div>
      <img :title="$t('download.ios')" alt="{{ $t('download.ios') }}" src="../assets/ios-qr.png" style="width: 100px;" >
      <p style="margin-top: 0px">{{ $t('download.scanqr') }}{{ $t('download.ios') }}</p>
    </div>
  </div>

</template>

<script>
import { setMeta, setTitle } from '../utils/setMeta'

export default {
  name: 'DownloadPage',
  created () {
    setTitle(this.$t('download.title'))
    setMeta('keywords', 'keywords', this.$t('download.keywords'))
    setMeta('description', 'description', this.$t('download.description'))
  }
}
</script>
<style scoped>
  .flex-items-center {
    align-items: center !important;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI Variable,Segoe UI,system-ui,ui-sans-serif,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    line-height: 1.5;
    padding-bottom: 0.571rem !important;
    padding-top: 0.571rem !important;
    text-align: center;
  }
</style>
