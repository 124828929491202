<template>
  <div class="flex-items-center" style="display: flow;">
    <p class="title">{{ $t('lastnames.title1') }}</p>
    <ol>
      <div v-for="(item, i) in this.lastNames" :index="i" :key="i">
        <li>
          <router-link :to="{ name: 'LastName', params: { lastname: item }}">
            {{ item }}姓
          </router-link>
        </li>
      </div>
    </ol>
  </div>
</template>

<script>
import { setMeta, setTitle } from '../../utils/setMeta'
import axios from 'axios'
import { useStore } from 'vuex'
import { computed } from 'vue'
const httpHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'api-version': 110,
  Authorization:
    '$X3t8&Rmm8+n0IATWO125^KK78aQ*p4&I2Y&s_9O8TGnH5g#SVb9l54&?75USS3u'
}
export default {
  name: 'LastNames',
  created () {
    setTitle(this.$t('lastnames.title'))
    setMeta('keywords', 'keywords', this.$t('lastnames.keywords'))
    setMeta('description', 'description', this.$t('lastnames.description'))
    this.fetchLastNames()
  },
  setup () {
    const store = useStore()
    return {
      lastNames: computed(() => store.state.lastNames)
    }
  },
  methods: {
    fetchLastNames () {
      axios.get(process.env.VUE_APP_BASE_URL + '/lastnames', { headers: httpHeader })
        .then((res) => {
          if (res.status === 200) {
            this.$store.commit('updateLastNames', res.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
  @import "../../assets/css/Shared.css";
</style>
