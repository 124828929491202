<template>
  <div class="flex-items-center" style="display: flex !important;">
    <div class="biglogo flex-items-left">
      <img :title="$t('common.site-name')" alt="{{$t('common.site-name')}}" src="../assets/起名.png" >
    </div>
    <div class="flex-items-right">
      <form  @submit.prevent="onSubmit">
        <p style="font-weight: 800 !important;font-size: 1.857rem !important;">{{ $t('home.big-slogan') }}</p>
        <div id="gender" style="margin: 0.143rem">
          <input class="radio" type="radio" value="male" v-model="gender" />
          <label class="home-text" style="margin-right: 31px">{{$t('common.male')}}</label>
          <input class="radio" type="radio" value="female" v-model="gender" />
          <label class="home-text" >{{$t('common.female')}}</label>
        </div>
        <div id="length" style="margin: 0.143rem">
          <input class="radio" type="radio" value="2" v-model="length" />
          <label class="home-text" style="margin-right: 1.071rem">{{$t('home.length2')}}</label>
          <input class="radio" type="radio" value="1" v-model="length" />
          <label class="home-text"  >{{$t('home.length1')}}</label>
        </div>
        <div style="margin: 0.143rem">
          <input class="common-input" v-model="lastName" v-bind:placeholder="$t('common.last-name')" name="lastName" id="lastName" autocomplete="off" spellcheck="false" >
        </div>
        <div style="margin: 0.143rem">
          <label class="home-text"  >{{$t('common.birthday')}}</label>
          <DatePicker mode="dateTime"  v-model="birthday" :timezone="timezone" :locale="locale" is24hr is-required :popover="{ visibility: 'click' }" >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="common-input"
                :value="inputValue"
                v-on="inputEvents"
                v-bind:placeholder="$t('common.birthday')"
              />
            </template>
          </DatePicker>
        </div>
        <button v-on:click="goSignup">
            {{ $t('home.name-button') }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { setTitle, setMeta } from '../utils/setMeta'

import { DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'
import axios from 'axios'
const httpHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'api-version': 110,
  Authorization:
    '$X3t8&Rmm8+n0IATWO125^KK78aQ*p4&I2Y&s_9O8TGnH5g#SVb9l54&?75USS3u'
}
export default {
  name: 'HomePage',
  components: {
    DatePicker
  },
  created () {
    setTitle(this.$t('home.title'))
    setMeta('keywords', 'keywords', this.$t('home.keywords'))
    setMeta('description', 'description', this.$t('home.description'))
  },
  computed: {
    lastName: {
      get () {
        return this.$store.state.lastName
      },
      set (value) {
        this.$store.commit('updateLastName', value)
      }
    },
    gender: {
      get () {
        return this.$store.state.gender
      },
      set (value) {
        this.$store.commit('updateGender', value)
      }
    },
    length: {
      get () {
        return this.$store.state.length
      },
      set (value) {
        this.$store.commit('updateLength', value)
      }
    },
    birthday: {
      get () {
        return this.$store.state.birthday
      },
      set (value) {
        this.$store.commit('updateBirthday', value)
      }
    }
  },
  data () {
    return {
      locale: (navigator.language !== 'zh-HK' && navigator.language !== 'zh-TW') ? 'zh' : navigator.language,
      end: new Date(),
      timezone: 'Asia/Hong_Kong',
      names: null,
      attributes: [{
        popover: {
          visibility: 'click'
        }
      }]
    }
  },
  methods: {
    validateLastName () {
      if (this.lastName.length === 0) {
        return false
      }
      if (this.lastName.length > 2) {
        return false
      }
      const re = /[^\u4e00-\u9fa5]/
      if (re.test(this.lastName)) {
        return false
      }
      return true
    },
    onSubmit: function (e) {
      if (!this.validateLastName()) {
        alert(this.$t('home.invalid-last-name'))
        return
      }
      this.names = null
      const mon = this.birthday.getMonth() + 1
      let monStr = mon.toString()
      if (mon < 10) {
        monStr = '0' + mon.toString()
      }
      const day = this.birthday.getDate()
      let dayStr = day.toString()
      if (day < 10) {
        dayStr = '0' + day.toString()
      }
      const hour = this.birthday.getHours()
      let hourStr = hour.toString()
      if (hour < 10) {
        hourStr = '0' + hour.toString()
      }
      const min = this.birthday.getMinutes()
      let minStr = min.toString()
      if (min < 10) {
        minStr = '0' + min.toString()
      }
      const dateString = this.birthday.getFullYear() + '-' + monStr + '-' + dayStr + 'T' + hourStr + ':' + minStr + ':00+08:00'
      const truncatedBirthday = new Date(this.birthday.getFullYear(), this.birthday.getMonth(), this.birthday.getDate(), this.birthday.getHours(), 0, 0)
      const body = JSON.stringify({
        gender: this.gender,
        birthday: dateString,
        birthdaySeconds: Math.round(truncatedBirthday / 1000),
        lastName: this.lastName,
        length: parseInt(this.length),
        specifyAt: 0,
        specify: '',
        deviceUniqueId: '',
        appVersion: '',
        appStore: 'webpage',
        phoneBrand: '',
        userId: ''
      })
      this.$store.commit('updateRequest', body)
      // this.$router.push({ name: 'NamesPage' })
      axios.post(process.env.VUE_APP_BASE_URL + '/naming', body, { headers: httpHeader })
        .then((res) => {
          if (res.status === 200) {
            // this.names =res.data
            this.$store.commit('updateNames', res.data)
            this.$router.push({ name: 'NamesPage' })
          }
        })
        .catch((error) => {
          // error.response.status Check status code
          console.log(error)
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import "../assets/css/Shared.css";
  @import "../assets/css/Home.css";
</style>
