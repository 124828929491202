<template>
  玄宗起名用户服务协议
  <br/>
  <br/>欢迎您（用户）与我方“玄宗起名运营方”共同签署本《用户服务协议》(下称“本协议”）并使用我方平台服务。
  <br/>
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;本协议的接受、变更和修改
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;定义
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;协议主体
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;帐号的注册与使用
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;平台服务使用规范
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;用户个人信息保护政策
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;虚拟货币
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;知识产权及其他权利
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;违约与处理
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;免责声明
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;协议终止
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;争议、法律适用与管辖
  <br/>
  <br/>您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可向我方平台咨询。
  <br/>
  <br/>当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与我方达成一致，成为我方平台“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
  <br/>
  <br/>如果您未申请注册流程，或者在本协议生效前已成为我方平台的注册用户，则您通过访问和/或使用我方平台（包括APP），即视为您表示同意接受本协议的全部内容，否则请您不要访问或使用我方平台。
  <br/>
  <br/>1.&nbsp;本协议的接受、变更、修改与补充
  <br/>
  <br/>1.1&nbsp;通过注册、访问和/或使用我方平台，您表示同意接受本协议的所有条件和条款。如果您不愿接受本协议的全部条件和条款，请您不要访问或使用我方平台。
  <br/>
  <br/>1.2&nbsp;我方有权根据国家法律法规变化及我方平台服务变化的需要不时对本协议进行修改，修改后的协议一旦公布即代替原来的协议，您需要不时在我方平台查阅最新版协议；您如果不同意本协议的修改，可以放弃使用或访问我方平台或取消已经获得的服务；如您对变更事项不同意的，您应当于变更事项确定的生效之日起停止使用我方平台服务，如果您选择在本协议变更后继续访问或使用我方平台，则视为您已经接受本协议的修改。
  <br/>
  <br/>1.3&nbsp;由于互联网行业高速发展，您与我方签署的本协议列明的条款并不能完整罗列并覆盖您与我方所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，我方平台公示的相关声明及政策、我方平台规则和协议均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用我方平台服务，视为您同意上述补充协议。
  <br/>
  <br/>2.&nbsp;定义
  <br/>
  <br/>我方平台：“玄宗起名运营方”拥有并运营的玄宗起名网站（https://www.xuanzong.info）、玄宗起名IOS和Android手机客户端软件以及其他各相关网站、网页、软件。
  <br/>
  <br/>我方：我方平台经营者，即“玄宗起名运营方”。
  <br/>
  <br/>本协议项下，我方平台经营者可能根据我方平台的业务调整而发生变更，变更后的我方平台经营者与您共同履行本协议并向您提供服务，我方平台经营者的变更不会影响您于本协议项下的权益。我方平台经营者还有可能因为提供新的我方平台服务而新增，如您使用新增的我方平台服务的，视为您同意新增的我方平台经营者与您共同履行本协议。发生争议时，您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。
  <br/>
  <br/>平台服务：指我方通过我方平台向您提供的任何视频的直播以及实时在线上传、视频观看、视频回放、内容互动与点赞、虚拟礼物馈赠、内容分享与传播等各种形式（包括将来可能出现的任何其他服务提供形式，无论是平台的新形态或视频直播、传播的新工具）的服务。
  <br/>
  <br/>平台规则：包括但不限于在所有我方平台内通过各频道、活动页面、站内信等已经发布及后续发布的全部规则、实施细则、产品说明、解读、公告等内容。
  <br/>
  <br/>注册用户/用户：包括通过我方平台直接注册帐号（无论是通过电话号码、邮箱、或其他任何方式）及通过其它任何被我方平台允许的第三方平台帐号登录、使用我方平台的用户。
  <br/>
  <br/>3.&nbsp;协议主体
  <br/>
  <br/>本协议由您与我方平台经营者“玄宗起名运营方”共同缔结，本协议对您与我方平台经营者均具有合同效力。
  <br/>
  <br/>4.&nbsp;帐号的注册与使用
  <br/>4.1&nbsp;用户资格
  <br/>
  <br/>您确认，在您开始使用/注册程序使用我方平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。如果您是未成年人，请在您的监护人的同意和指导下访问和/或使用我方平台。
  <br/>4.2&nbsp;帐号说明
  <br/>
  <br/>4.2.1&nbsp;当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或者您通过其它我方授权的第三方平台等账号登录我方平台的客户端或者网站，您可获得我方平台帐号并成为我方平台用户。
  <br/>您有权使用您设置或确认的会员名、邮箱、手机号码等（以下简称“帐号名称”）及您设置的密码（帐号名称及密码合称“帐号”）登录我方平台。
  <br/>
  <br/>4.2.2&nbsp;由于用户帐号关联用户信息，仅当有法律明文规定、司法裁定或经我方同意，并符合我方平台规则规定的用户帐号转让流程的情况下，您才可进行帐号的转让。您的帐号一经转让，该帐号项下权利义务一并转移。除此外，您的帐号不得以任何方式转让，否则由此产生的一切责任均由您承担。
  <br/>
  <br/>4.2.3&nbsp;为使您更好地使用我方平台的各项服务，请您关注我方平台本协议及/或使用要求或建议，按照我方平台要求及相关法律规定完成实名认证。
  <br/>
  <br/>4.2.4&nbsp;如您的帐号长期未登录（一般为超过24个月未登录），我方有权予以进行注销等清理，您的帐号如被注销清理，您将不能再使用该帐号登录任一我方平台，相应服务同时终止。我方在对此类帐号进行清理前，将以包括但不限于网站公告、站内消息、客户端推送信息等方式通知您。
  <br/>4.3&nbsp;注册信息
  <br/>
  <br/>信息合法合规
  <br/>
  <br/>4.3.1&nbsp;在使用我方平台服务时，您应当按我方平台页面的提示准确完整地提供您的信息，以便我方在必要时与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。
  <br/>
  <br/>4.3.2&nbsp;您所设置的帐号名不得违反国家法律法规及我方平台规则关于帐号名的管理规定，否则我方可对您的帐号名进行暂停使用或注销等处理，并向主管机关报告。您理解并承诺，您的帐号名称、头像和简介等注册信息中不得出现违法和不良信息，没有冒用、关联机构或社会名人，您在帐号注册过程中需遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。
  <br/>
  <br/>您同意并授权，为了更好的为您提供服务以及确保您的帐号安全，我方可以根据您提供的手机号码、身份证号码等信息（如涉及），向全国公民身份号码查询服务中心、电信运营商、金融服务机构等可靠单位发起用户身份真实性、用户征信记录、用户手机号码有效性状态等情况的查询。
  <br/>4.4&nbsp;信息更新
  <br/>
  <br/>您应当及时更新您提供的信息，在法律有明确规定要求我方对部分用户的信息进行核实的情况下，我方将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整的信息。
  <br/>
  <br/>如我方按您最后一次提供的信息与您联系未果、您未按照我方的要求及时提供信息、您提供的信息存在明显不实的，您将承担因此对您自身、他人及我方造成的全部损失与不利后果。
  <br/>4.5&nbsp;帐号保管
  <br/>
  <br/>4.5.1&nbsp;您的帐号为您自行设置并由您保管。建议您务必保管好您的帐号，并确保您在每个上网时段结束时退出登录并以正确步骤离开我方平台。
  <br/>帐号因您主动泄露或遭受他人攻击、诈骗等行为导致的损失及后果，均由您自行承担。
  <br/>
  <br/>4.5.2&nbsp;您的帐号只限您本人使用，不得出借或分享他人使用。当您的帐号遭到未经授权的使用时，您应当立即通知我方，否则未经授权的使用行为均视为您本人的行为，您将自行承担所有由此导致的损失及后果。
  <br/>除我方存在重大过错外，您应对您帐号项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。
  <br/>
  <br/>4.5.3&nbsp;如发现任何未经授权使用您帐号登录我方平台或其他可能导致您帐号遭窃、遗失的情况，建议您立即通知我方。您理解我方对您的任何请求采取行动均需要合理时间，除我方存在重大过错外，我方对在采取行动前已经产生的后果不承担任何责任。
  <br/>
  <br/>4.5.4&nbsp;若帐号的归属出现争议的，我方在收到相关方的投诉后，有权暂时冻结该争议帐号；争议各方在举证期（15个工作日）内提供证据证明帐号归属，我方依据各方提供的证据判断归属后，解冻争议帐号。
  <br/>
  <br/>5.&nbsp;平台服务使用规范
  <br/>5.1&nbsp;平台服务
  <br/>
  <br/>5.1.1&nbsp;我方向您提供以下网络平台服务，包括通过其拥有的或运营的网站和手机、PC、平板等全部终端客户端：
  <br/>1）玄宗起名（包括但不限于视频的直播、直播视频回放、视频观看、内容互动与点赞、虚拟礼物馈赠、内容分享与传播）。
  <br/>2）&nbsp;其他技术或服务。
  <br/>
  <br/>我方平台服务，均仅限于您在我方平台使用，任何以恶意破解等非法手段将我方服务与我方平台分离的行为，均不属于本协议中约定的我方服务。由此引起的一切法律后果由行为人负责，我方将依法追究行为人的法律责任。
  <br/>
  <br/>5.1.2&nbsp;我方平台公布的方式为下载、注册、登录、使用我方服务的唯一合法方式，您通过其他任何渠道、任何途径、任何方式获取的我方服务（包括且不限于帐号、积分、经验值、积分商品、兑换商品、赠品、下载等）均为非法取得，我方概不承认其效力，且一经发现我方有权立即做出删除、取消、清零、封号等处理，任何因此导致的一切不利后果均由您自行承担。
  <br/>
  <br/>5.1.3&nbsp;我方有权根据国家法律法规变化及我方平台服务变化的需要不时修改、替换、升级与我方平台服务相关的任何软件，如果您不同意或者不接受我方平台服务相关软件的修改、替换、升级，请直接拒绝、停止、取消，否则视为您同意并接受我方平台相关软件的修改、替代、升级，同时该同意并接受的行为仍受本协议约束。
  <br/>
  <br/>5.1.4&nbsp;您您理解并认可，您使用我方平台或我方平台帐号所获得的使用记录、订阅、收藏、积分、经验值、成长值、等级、身份标识、赠券、虚拟积分商品、虚拟兑换商品、虚拟赠品、下载等衍生物，您确认对其不享有所有权（除非我方平台另有公告说明），我方许可您按照我方平台规则进行使用，我方对上述衍生物不承担任何赔偿责任。
  <br/>
  <br/>5.1.5&nbsp;您理解并认可，如果您通过第三方支付工具在我方平台帐号支付或充值后可能产生的任何商业风险&nbsp;（包括但不限于不法分子利用您帐号或银行卡等有价卡等进行违法活动），该等风险均有可能给您造成相应的经济损失，我方在充分履行其在本协议项下义务和符合法律规定的前提下，不对您的前述风险和损失承担任何责任。
  <br/>
  <br/>5.1.6&nbsp;您理解并认可，我方不对您在使用我方平台服务中产生的相关数据的删除或储存失败负责。我方可以根据实际情况自行决定用户在使用我方平台服务中产生的数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份您使用我方平台服务中的相关数据。您停止使用我方平台服务或我方平台服务终止，我方可以从服务器上永久地删除用户的数据。我方平台服务停止、终止后，我方没有义务向您返还任何数据。
  <br/>5.2&nbsp;平台规范
  <br/>
  <br/>5.2.1&nbsp;您同意并保证，不得利用我方平台服务或其衍生物（包括但不限于帐号、积分、等级、积分商品、活动赠品、下载等服务）进行倒卖、转手、置换、抵押有价交易等方式非法牟利。您不会利用我方平台服务或其衍生物侵犯他人的合法权益，禁止通过网络漏洞、恶意软件或其他非法手段窃取、盗用他人的帐号、积分、积分商品等。
  <br/>
  <br/>5.2.2&nbsp;您可通过我方平台服务在我方平台上传、发布或传输相关内容，包括但不限于文字、图形、图片、声音、音乐、视频、音视频、链接等信息或其他资料（下称“内容”），但您需对此内容承担相关的法律责任。
  <br/>除非有相反证明，我方将您视为您在我方平台上传、发布或传输的内容的版权拥有人或已经获得相关合法授权（无论是默许授权还是明示授权）。您使用我方平台上传、发布或传输内容即代表了您有权且同意在全世界范围内，永久性的、不可撤销的、免费的授予我方对该内容的存储、使用、发布、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示等权利；将内容的全部或部分编入其他任何形式的作品、媒体、技术中的权利；对您的上传、发布的内容进行商业开发的权利；通过有线或无线网络向您的计算机终端、移动通讯终端（包括但不限于便携式通讯设备如手机和智能平板电脑等）、其他带由互联网接入功能的设备等提供信息的下载、点播、数据传输、移动视频业务（包括但不限于SMS、MMS、WAP、IVR、Streaming、3G、手机视频等无线服务）、及其相关的宣传和推广等服务的权利；以及再授权给其他第三方以上述方式使用的权利。
  <br/>
  <br/>5.2.3&nbsp;为方便您使用我方和我方关联公司的其他相关服务，您授权我方将您在帐号注册和使用我方平台服务过程中提供、形成的信息传递给我方关联公司等其他相关服务提供者，或从我方关联公司等其他相关服务提供者获取您在注册、使用相关服务期间提供、形成的信息。
  <br/>
  <br/>5.2.4&nbsp;您理解并知晓在使用我方平台服务时，所接触的内容和信息来源广泛，我方无法对该内容和信息的准确性、真实性、可用性、安全性、完整性和正当性负责。您理解并认可您可能会接触到不正确的、令人不快的、不适当的或令人厌恶的内容和信息，您不会以此追究我方的相关责任。我方不对用户在我方平台上传、发布或传输的任何内容和信息背书、推荐或表达观点，也不对任何内容和信息的错误、瑕疵及产生的损失或损害承担任何责任，您对内容和信息的任何使用需自行承担相关的风险。
  <br/>
  <br/>5.2.5&nbsp;您同意我方在提供服务的过程中以各种方式投放商业性广告或其他任何类型的商业信息（包括但不限于在我方平台的任何位置上投放广告，在您上传、传播的内容中投放广告），您同意接受我方通过电子邮件、手机短信、网站公告或其他方式向您发送相关商业信息。对于上述商业性质邮件或短信，您有权进行邮件、短信设置或联系客服等方式拒收或退订该类信息。
  <br/>
  <br/>5.2.6&nbsp;您同意在使用我方平台服务过程中，遵守《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《信息网络传播权保护条例》、《中华人民共和国侵权责任法》等有关计算机及互联网规定的法律、法规。在任何情况下，我方一旦合理地认为您的行为可能违反上述法律、法规，可以在任何时候，不经事先通知终止向您提供服务。
  <br/>
  <br/>5.2.7&nbsp;禁止用户从事以下行为：
  <br/>
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;1）上载、张贴、发送电子邮件或传送包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容或其他另人反感的包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料（以下简称内容）；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;2）以任何方式危害未成年人；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;3）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;4）伪造标题或以其他方式操控识别资料，使人误认为该内容为我方平台经营者所传送；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;5）将无权传送的内容（例如内部资料、机密资料）进行上载、张贴、发送电子邮件或以其他方式传送；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;6）将侵犯任何人的专利、商标、著作权、商业秘密或其他专属权利之内容加以上载、张贴、发送电子邮件或以其他方式传送；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;7）将广告函件、促销资料、“垃圾邮件”等，加以上载、张贴、发送电子邮件或以其他方式传送。供前述目的使用的专用区域除外；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;8）将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;9）干扰或破坏本网站服务或与本网站服务相连的服务器和网络，或不遵守本网站使用之规定；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;10）通过使用任何自动化程序、软件、引擎、网络爬虫、网页分析工具、数据挖掘工具或类似工具，接入我方平台服务、收集或处理通过我方平台服务所提供的内容；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;11）参与任何“框架”、“镜像”或其他技术，目的是模仿我方平台服务的外观和功能；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;12）干预或试图干预任何用户或任何其他方接入我方平台服务；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;13）未经他人明确同意，分享或发布该等人士可识别个人身份的资料；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;14）测试我方平台服务、系统或其他用户的系统是否容易入侵攻击，或在其他方面规避（或试图规避）我方平台服务、系统或其他用户的系统的任何安全功能；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;15）对我方平台服务所用的软件进行解编、反向编译或逆向工程，或试图作出上述事项；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;16）为破坏或滥用的目的开设多个帐号，或恶意上传重复的、无效的大容量数据和信息；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;17）故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。
  <br/>
  <br/>
  <br/>6.&nbsp;用户个人信息保护政策
  <br/>
  <br/>6.1&nbsp;用户注册我方平台帐号并/或使用我方平台服务时，用户须提供个人信息。我方平台收集个人信息的目的是为用户提供尽可能多的个人化网上服务以及为广告商提供一个方便的途径来接触到适合的用户，并且可以发送具有相关性的内容和广告。在此过程中，广告商绝对不会接触到用户的个人信息。我方平台亦不会在未经合法用户授权时，公开、编辑或透露其个人信息及保存在我方平台中的非公开内容，除非有下列情况：
  <br/>
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;1）事先获得用户的明确授权；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;2）根据有关的法律法规要求；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;3）按照相关政府主管部门的要求；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;4）在紧急情况下，为维护用户及公众的权益；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;5）其他需要公开、编辑或透露个人信息的情况。
  <br/>
  <br/>6.2&nbsp;为了提供并优化您需要的服务，我方会收集您使用服务的相关信息，这类信息包括：
  <br/>在您使用我方平台服务，或访问我方平台时，我方自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；如您下载或使用我方或其关联公司客户端软件，或访问移动网页使用我方平台时，我方可能会读取与您位置和移动设备相关的信息，包括但不限于设备型号、设备识别码、操作系统、分辨率、电信运营商等。您通过我方平台的内容上传服务所上传的内容所包含的信息，例如拍摄或上传的共享照片或录像的日期、时间或地点等。
  <br/>
  <br/>除上述信息外，我方还可能为了提供服务及改进服务质量的合理需要而收集您的其他信息，包括您与我方的客户服务团队联系时您提供的相关信息，您参与问卷调查时向我方发送的问卷答复信息，以及您与我方的关联方、我方合作伙伴之间互动时我方收集的相关信息。
  <br/>
  <br/>6.3&nbsp;为了给您提供更好、更加个性化的服务，或共同为您提供服务，或为了预防互联网欺诈的目的，我方的关联方、合作伙伴会依据法律的规定或与您的约定或征得您同意的前提下，向我方分享您的个人信息。
  <br/>
  <br/>6.4&nbsp;本用户个人信息保护政策不适用于其他第三方向您提供的服务，例如我方平台上的第三方依托我方平台向您提供服务时，您向第三方提供的个人信息不适用于本用户个人信息保护政策，我方对任何第三方使用由您提供的信息不承担任何责任。
  <br/>
  <br/>6.5&nbsp;我方将遵循由中国广告协会互动网络分会颁布的《中国互联网定向广告用户信息保护行业框架标准》，基于合法、合理和诚实信用的法定原则妥善处理或使用您的个人信息。
  <br/>
  <br/>6.6&nbsp;我方收集的有关您的信息和资料将保存在我方及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或我方收集信息和资料所在地并在该地被访问、存储和展示。
  <br/>
  <br/>6.7&nbsp;为保障您的信息安全，我方会努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失。我方对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签保密协议，监控他们的操作情况等措施。我方会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我方将尽力做到使您的信息不被泄漏、毁损或丢失。
  <br/>
  <br/>6.8&nbsp;您的帐号均有安全保护功能，请妥善保管您的帐号及密码信息。我方将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完善的安全措施”。
  <br/>
  <br/>6.9&nbsp;在使用我方平台服务进行网上交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如银行账户信息、联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是您的帐号及密码发生泄露，请您立即联络我方客服，以便我方采取相应措施。
  <br/>
  <br/>6.10&nbsp;我方不会在知情的情况下收集未成年人的个人信息。除非所在地法律允许并且监护人同意，未成年人请不要注册帐号或发送自己的姓名、住址、电话、邮件地址等个人信息给我方。如果我方不小心收集到了未成年人的信息，我方在知情后会尽快删除。如果您认为我方可能不当地持有来自于或关于未成年人的信息，请联系我方。
  <br/>
  <br/>6.11&nbsp;更详细的用户个人信息保护政策内容可参见我方另行公布的《玄宗起名个人信息保护政策》及类似文件。
  <br/>
  <br/>7.&nbsp;虚拟货币
  <br/>
  <br/>7.1&nbsp;我方将在“玄宗起名”平台发行虚拟货币，即金币（名称的变化不影响本条的适用）。金币可用于购买“玄宗起名”平台的各类增值服务，该等增值服务的价格均以金币为单位，具体价格信息将由我方自行决定并在相关服务页面上显示。
  <br/>
  <br/>7.2&nbsp;您默认已开通金币账户，可进行金币购买和消费。您可在“我的”页面查询到您的金币余额。
  <br/>
  <br/>7.3&nbsp;您可以通过网上银行、支付宝或其他“玄宗起名”平台提供的充值途径为金币账户进行充值。您使用金币购买相关收费服务后，可将相关收费服务赠与其他用户。
  <br/>
  <br/>7.4&nbsp;您确认，金币一经充值成功，除法律法规明确规定外，在任何情况下不能兑换为法定货币，不能转让他人。除法律法规明确规定外，金币账户充值完成后，我方不予退款。
  <br/>
  <br/>7.5&nbsp;您确认，金币只能用于购买“玄宗起名”平台上的各类增值服务，任何情况下不得与我方以外的第三方进行金币交易，亦不得在除“玄宗起名”平台以外的第三方平台（如淘宝）上进行交易；如违反前述约定，造成您或第三方任何损失，我方不负任何责任，且如我方有理由怀疑您的金币账户或使用情况有作弊或异常状况，我方将拒绝您使用金币进行支付，直至按本协议约定采取相关封禁措施。
  <br/>
  <br/>7.6&nbsp;您确认，除法律法规明确规定或本协议另有约定外，您已购买的任何收费服务不能以任何理由退购（即退换成法定货币）或调换成其他服务。
  <br/>
  <br/>7.7&nbsp;您应遵守本协议的各项条款，正确、适当地使用本服务，不得扰乱平台秩序，包括但不限于扰乱平台金融秩序（如出售金币等）。如您违反本协议或其他平台规则的任何条款，我方有权依据协议终止/暂停对违约用户账户（包括相应的帐号）提供全部或部分服务。若帐号任何功能被终止/中止的，无法参加任何官方活动。若账户（包括相应的帐号）被永久封禁的，账户内的金币将被全部清空。需要特别注意的是，涉嫌使用不合理手段充值（包括但不限于非法使用信用卡套现）的账户，我方依其合理判断将暂时或永久封停该账户以及对应的帐号。同时，我方保留在任何时候收回帐号、账户、用户名的权利。
  <br/>
  <br/>8.&nbsp;知识产权及其他权利
  <br/>
  <br/>8.1&nbsp;除非另有约定或我方另行声明，我方平台内的所有内容（用户依法享有版权的内容除外）、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利，均归我方或我方关联公司所有。未经我方许可，任何人不得擅自使用（包括但不限于复制、传播、展示、镜像、上载、下载、修改、出租）。
  <br/>
  <br/>8.2&nbsp;我方平台的Logo、“玄宗起名”等文字、图形及其组合，以及我方平台的其他标识、徵记、产品和服务名称均为我方或我方关联公司在中国或其它国家的商标，未经我方书面授权，任何人不得以任何方式展示、使用或作其他处理，也不得向他人表明您有权展示、使用或作其他处理。
  <br/>
  <br/>8.3&nbsp;经由我方平台传送的视频及其它内容，受到著作权法、商标法、专利法或其他法律的保护；除该等视频及其它内容上载的用户所享有的版权，未经我方平台明示授权许可，其他用户不得进行修改、出租、散布或衍生其他作品。
  <br/>
  <br/>8.4&nbsp;您在我方平台提供直播服务期间产生的任何成果（包括但不限于直播视频、音频，以下统称“主播成果”）的知识产权由您享有。您同意许可我方在全世界范围内免费地、永久性地、不可撤销地、独家地使用和可再许可及（或）转授权任意第三方使用主播成果，包括但不限于复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及《著作权法》规定的由著作权人享有的其他著作财产权利，我方无须对此额外向您支付任何费用。
  <br/>
  <br/>8.5&nbsp;任何第三方未经我方同意，不得将您在我方平台上的主播成果、所发表言论等进行复制、修改、编辑、转让、使用、通过信息网络传播或作其他用途，包括但不限于通过截取视频信号、抓取录播音频／视频、编辑视频/音频/文字和其他形式的内容等方式。否则，您同意许可我方有权就任何主体侵权而以自己的名义单独或委托第三方机构提起诉讼或开展其他法律行动，并获得全部赔偿。
  <br/>
  <br/>9.违约与处理
  <br/>
  <br/>9.1&nbsp;违约行为认定
  <br/>
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;1）在使用我方平台过程中违反有关法律法规规定；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;2）违反本协议及其补充协议规定的。
  <br/>
  <br/>我方可能根据相关数据的采集与对比分析来分析您对我平台的使用行为，如果我方认为您的某些行为存在不妥或者违约嫌疑时，您应当在我方的要求下对您的异常数据做出合理的解释与举证，否则我方可认定您构成违约。
  <br/>9.2&nbsp;违约行为的处理
  <br/>
  <br/>对于您在我方平台使用过程中出现违约行为的，我方根据违约情节程度，可对您的帐号或发布内容采取暂停使用、查封、注销或删除、屏蔽、清空等处理。
  <br/>
  <br/>您在我方平台上实施的行为，或虽未在我方平台上实施但对我方平台及其用户产生影响的行为构成违约的，我方可依据相应规则对您的帐号执行限制参加活动、中止向您提供部分或全部服务等处理措施。如您的行为构成严重不良影响或其他与我方平台服务完全抵触的、或构成根本违约的，我方可查封您的帐号，终止向您提供服务，并根据您违约的情况追究您的法律责任。
  <br/>9.3&nbsp;赔偿责任
  <br/>
  <br/>如您的行为使我方及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等），您应赔偿我方及/或其关联公司的上述全部损失。
  <br/>如您的行为使我方及/或其关联公司遭受第三人主张权利，我方及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。
  <br/>
  <br/>10.&nbsp;免责声明
  <br/>
  <br/>10.1&nbsp;我方根据本协议及相关法律法规向您提供平台服务，依法承担义务，但我方对于因信息网络设备维护、连接故障、电脑、通讯或其他系统的故障、黑客活动、计算机病毒、电力故障、罢工、暴乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令或因第三方原因而给您造成的损害结果不承担责任。
  <br/>
  <br/>10.2&nbsp;我方通过中华人民共和国境内的设施控制和提供我方平台服务，我方不担保控制或提供的服务在其他国家或地区是适当的、可行的，任何在其他司法辖区使用我方平台服务的用户应自行确保其遵守当地的法律法规，我方对此不承担任何责任。
  <br/>
  <br/>10.3&nbsp;您理解并同意，任何因您在我方平台的行为（包括但不限于侵犯他人合法权益、违反知识产权相关规定或者其他违法违约行为等）导致或产生的与第三方争议、追诉索赔的，应当由您独立负责处理，我方对此不承担责任。
  <br/>
  <br/>10.4&nbsp;您同意我方平台通过以下方式向您发送通知：
  <br/>
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;1）站内消息、弹出消息、客户端推送的消息；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;2）向您预留的电话号码、邮箱发送相关消息;
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;3）平台内文案等。
  <br/>
  <br/>
  <br/>11.&nbsp;协议终止
  <br/>11.1&nbsp;您有权通过以下任一方式终止本协议：
  <br/>
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;1）在满足我方平台公示的帐号注销等清理条件时您通过我方平台注销您的帐号的；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;2）变更事项生效前您停止使用并明示不愿接受变更事项的；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;3）您明示不愿继续使用我方平台服务，且符合我方平台终止条件的。
  <br/>
  <br/>11.2&nbsp;您在使用我方平台过程中出现以下情形的，我方可以本协议第10条的所列的方式通知您终止本协议：
  <br/>
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;1）您违反本协议约定，我方依据违约条款终止本协议的；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;2）您转让本人帐号、盗用他人帐号、发布违禁内容和信息、骗取他人财物、采取不正当手段谋利等行为，我方依据我方平台规则对您的帐号予以查封的；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;3）除上述情形外，因您多次违反我方平台规则相关规定且情节严重，我方依据我方平台规则对您的帐号予以查封的；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;4）您的帐号被我方依据本协议进行注销等清理的；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;5）您在我方平台有侵犯他人合法权益或其他严重违法违约行为的；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;6）其它根据相关法律法规我方应当终止服务的情况。
  <br/>
  <br/>11.3&nbsp;您与我方的协议终止后，我方无义务向您或您指定的第三方披露您帐号中的任何信息，法律具有强制性规定的除外。
  <br/>
  <br/>本协议终止后，我方享有下列权利：
  <br/>
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;1）可继续保存您留存于我方平台的其他内容和信息（您要求删除的除外）；
  <br/>&nbsp;&nbsp;&nbsp;&nbsp;2）对于您过往的违约行为，我方仍可依据本协议向您追究违约责任。
  <br/>
  <br/>
  <br/>12.&nbsp;争议、法律适用与管辖
  <br/>
  <br/>12.1&nbsp;本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国法律；如法律无相关规定的，参照商业惯例及/或行业惯例。
  <br/>
  <br/>12.2&nbsp;您因使用我方平台服务所产生及与我方平台服务有关的争议，由我方与您协商解决。协商不成时，任何一方均可向我方所在地人民法院提起诉讼。
  <br/>
  <br/>12.3&nbsp;本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
</template>
<script>
import { setMeta, setTitle } from '../../utils/setMeta'

export default {
  name: 'TermsPage',
  created () {
    setTitle(this.$t('terms.title'))
    setMeta('keywords', 'keywords', this.$t('terms.keywords'))
    setMeta('description', 'description', this.$t('terms.description'))
  }
}
</script>
