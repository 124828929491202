<template>
  <div class="flex-items-center">
    <p class="title">{{ $t('about.title1') }}</p>
    <p>{{ $t('about.content1') }}</p>
    <p><a href="https://xuanzong.info">{{ $t('about.content2') }}</a>{{ $t('about.content3') }}</p>
  </div>

</template>

<script>
import { setMeta, setTitle } from '../../utils/setMeta'

export default {
  name: 'AboutUs',
  created () {
    setTitle(this.$t('about.title'))
    setMeta('keywords', 'keywords', this.$t('about.keywords'))
    setMeta('description', 'description', this.$t('about.description'))
  }
}
</script>
<style scoped>
  .flex-items-center {
    align-items: center !important;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI Variable,Segoe UI,system-ui,ui-sans-serif,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    line-height: 1.5;
    padding-bottom: 0.571rem !important;
    padding-top: 0.571rem !important;
    text-align: left;
  }
  .title {
    font-size: 1.714rem;
    font-weight: 400;
    margin-bottom: 0.571rem;
  }
</style>
