<template>
  <div class="flex-items-center" v-if="this.names">
    <p class="title">{{$t('names.title-fenxi')}}</p>
    <p class="section">{{$t('names.baseinfo')}}</p>
    <label class="label-title">{{$t('common.last-name')}}:</label><label class="label-value">{{this.names.request.lastName}}</label>
    <label class="label-title">{{$t('common.gender')}}:</label><label class="label-value">{{this.names.request.gender}}</label>
    <label class="label-title">{{$t('common.shengxiao')}}:</label><label class="label-value">{{this.names.solar.animal}}</label>
    <label class="label-title">{{$t('common.xingzuo')}}:</label><label class="label-value">{{this.names.solar.constellation}}</label>
    <label class="label-title">{{$t('common.birthday')}}:</label><label class="label-value">{{this.names.solar.year}}{{$t('common.year')}}{{this.names.solar.month}}{{$t('common.month')}}{{this.names.solar.day}}{{$t('common.day')}} {{this.names.solar.hour}}{{$t('common.hour')}}</label>
    <label class="label-title">{{$t('common.lunar-birthday')}}:</label><label class="label-value">{{this.names.lunar.year_alias}}{{$t('common.year')}}{{this.names.lunar.month_alias}}{{this.names.lunar.day_alias}}{{$t('common.day')}} {{this.names.ganzhi.hour.split('')[1]}}{{$t('common.hour')}}</label>
    <p class="section">{{$t('common.bazi')}}</p>
    <label class="label-title">{{$t('common.tiangan')}}:</label><label class="label-value">{{this.names.ganzhi.year.split('')[0]}} {{this.names.ganzhi.month.split('')[0]}} {{this.names.ganzhi.day.split('')[0]}} {{this.names.ganzhi.hour.split('')[0]}}</label>
    <label class="label-title">{{$t('common.dizhi')}}:</label><label class="label-value">{{this.names.ganzhi.year.split('')[1]}} {{this.names.ganzhi.month.split('')[1]}} {{this.names.ganzhi.day.split('')[1]}} {{this.names.ganzhi.hour.split('')[1]}}</label>
    <p class="section">{{$t('names.wuxing-count')}}</p>
    <label class="label-title">{{$t('common.jin')}}:</label><label class="label-value">{{this.names.wuxing.jin}}</label>
    <label class="label-title">{{$t('common.mu')}}:</label><label class="label-value">{{this.names.wuxing.mu}}</label>
    <label class="label-title">{{$t('common.shui')}}:</label><label class="label-value">{{this.names.wuxing.shui}}</label>
    <label class="label-title">{{$t('common.huo')}}:</label><label class="label-value">{{this.names.wuxing.huo}}</label>
    <label class="label-title">{{$t('common.tu')}}:</label><label class="label-value">{{this.names.wuxing.tu}}</label>
    <p/>
    <label class="label-value">
      {{ (this.names.wuxing.jin > 0 && this.names.wuxing.mu > 0 && this.names.wuxing.shui > 0 && this.names.wuxing.huo > 0 && this.names.wuxing.tu > 0) ? $t('names.wuxing-all'): $t('names.wuxing-miss')+ (
      (this.names.wuxing.jin === 0 ? '金' : '') +
      (this.names.wuxing.mu === 0 ? '木' : '') +
      (this.names.wuxing.shui === 0 ? '水' : '') +
      (this.names.wuxing.huo === 0 ? '火' : '') +
      (this.names.wuxing.tu === 0 ? '土' : '')
      )
      }}
    </label>
    <p/>
    <label class="label-value">
      {{$t('names.wuxing-notes')}}
    </label>
    <p class="section">{{$t('names.wuxing-value')}}</p>
    <label class="label-title">{{$t('common.jin')}}:</label><label class="label-value">{{new Number(this.names.wuxing.jinPoint).toFixed(2)}}</label>
    <label class="label-title">{{$t('common.mu')}}:</label><label class="label-value">{{new Number(this.names.wuxing.muPoint).toFixed(2)}}</label>
    <label class="label-title">{{$t('common.shui')}}:</label><label class="label-value">{{new Number(this.names.wuxing.shuiPoint).toFixed(2)}}</label>
    <label class="label-title">{{$t('common.huo')}}:</label><label class="label-value">{{new Number(this.names.wuxing.huoPoint).toFixed(2)}}</label>
    <label class="label-title">{{$t('common.tu')}}:</label><label class="label-value">{{new Number(this.names.wuxing.tuPoint).toFixed(2)}}</label>
    <p class="section">{{$t('common.xiyongshen')}}</p>
    <label class="label-title">{{$t('common.xiyongshen')}}:</label><label class="label-value">{{this.names.wuxing.xiYongShen}}</label>
    <p/>
    <label class="label-value">{{this.names.wuxing.xiYongShenComment}}</label>
    <p class="section">{{$t('names.shenxiao-prefer')}}</p>
    <label class="label-title">{{$t('common.yi')}}:</label><label class="label-value">{{this.names.shengXiaoPreference.宜}}</label>
    <p/>
    <label class="label-value" style="white-space: pre-wrap;">
      {{this.names.shengXiaoPreference.good_explain}}
    </label>
    <p/>
    <label class="label-title">{{$t('common.ji')}}:</label><label class="label-value" style="color: red">{{this.names.shengXiaoPreference.忌}}</label>
    <p/>
    <label class="label-value" style="white-space: pre-wrap;">
      {{this.names.shengXiaoPreference.bad_explain}}
    </label>
<!--    免费吉名-->
    <br/>
    <label class="title">{{$t('names.free-names')}}</label><router-link :to="{name: 'DownloadPage'}"> {{ $t('names.download-tip') }}</router-link>
    <br/>
    <div v-for="(item, i) in this.names.names" :index="i" :key="i">
      {{i+1}}&nbsp;&nbsp;&nbsp;&nbsp;
      <router-link :title="this.names.request.lastName + item.Name + $t('jieming.title')" :to="{ name: 'JieMingPage', query: {firstName:encodeURIComponent(item.Name), lastName: encodeURIComponent(this.names.request.lastName), gender: this.names.request.gender, birthday: this.names.request.birthday, manual: 'false'}}">
        {{ this.names.request.lastName }}{{ item.Name }}
      </router-link>
&nbsp;&nbsp;&nbsp;&nbsp; <label style="color: #2ea44f; font-size: 1.143rem; font-weight: bold">{{item.Score}}</label>{{$t('common.score')}}
      <p/>
      <div class="fenxi">
        <div style="display: flex !important;">
          <label class="label-title">{{$t('common.bazi')}}:</label>
          <div v-if="item.ScoreDetail.xiYongShenAlias === '利'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
              <title>{{$t('names.xiyongshen-good')}}</title>
            </svg>
          </div>
          <div v-else>
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
              <title>{{$t('names.xiyongshen-bad')}}</title></svg>
          </div>
        </div>
        <div style="display: flex !important;">
          <label class="label-title">{{$t('common.shengxiao')}}:</label>
          <div v-if="item.ScoreDetail.shengXiaoAlias === '利'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
              <title>{{$t('names.shengxiao-good')}}</title>
            </svg>
          </div>
          <div v-else-if="item.ScoreDetail.shengXiaoAlias === '弊'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
              <title>{{$t('names.shengxiao-bad')}}</title>
            </svg>
          </div>
          <div v-else>
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
              <title>{{$t('names.shengxiao-non')}}</title>
            </svg>
          </div>
        </div>
        <div style="display: flex !important;">
          <label class="label-title">{{$t('common.pinge')}}:</label>
          <div v-if="item.ScoreDetail.pingZeAlias === '异平仄'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
              <title>{{$t('names.fayin-good')}}</title>
            </svg>
          </div>
          <div v-else-if="item.ScoreDetail.pingZeAlias === '同声调'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
              <title>{{$t('names.fayin-bad')}}</title>
            </svg>
          </div>
          <div v-else>
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
              <title>{{$t('names.fayin-part-bad')}}</title>
            </svg>
          </div>
        </div>
        <div style="display: flex !important;">
          <label class="label-title">{{$t('common.gender')}}:</label>
          <div v-if="item.ScoreDetail.genderAlias !== '有'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
              <title>{{$t('names.gender-good')}}</title>
            </svg>
          </div>
          <div v-else>
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
              <title>{{$t('names.gender-bad')}}</title>
            </svg>
          </div>
        </div>
        <div style="display: flex !important;">
          <label class="label-title">{{$t('names.stroke')}}:</label>
          <div v-if="item.ScoreDetail.strokeAlias === '简单'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
              <title>{{$t('names.stroke-good')}}</title>
            </svg>
          </div>
          <div v-else>
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
              <title>{{$t('names.stroke-bad')}}</title>
            </svg>
          </div>
        </div>
        <div style="display: flex !important;">
          <label class="label-title">{{$t('names.meaning')}}:</label>
          <div v-if="item.ScoreDetail.meaningAlias !== '有'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
              <title>{{$t('names.meaning-good')}}</title>
            </svg>
          </div>
          <div v-else>
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
              <title>{{$t('names.meaning-bad')}}</title>
            </svg>
          </div>
        </div>
        <div style="display: flex !important;">
          <label class="label-title">{{$t('names.yinyun')}}:</label>
          <div v-if="item.ScoreDetail.dieYunAlias === '不叠韵'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
              <title>{{$t('names.yinyun-good')}}</title>
            </svg>
          </div>
          <div v-else-if="item.ScoreDetail.dieYunAlias === '全叠韵'">
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
              <title>{{$t('names.yinyun-bad')}}</title>
            </svg>
          </div>
          <div v-else>
            <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
              <title>{{$t('names.yinyun-part-bad')}}</title>
            </svg>
          </div>
        </div>
      </div>
      <hr/>
    </div>
    <p/>
    <router-link :to="{name: 'DownloadPage'}"> {{ $t('names.download-tip') }}</router-link>
  </div>
</template>

<script>
import { setMeta, setTitle } from '../utils/setMeta'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  name: 'NamesPage',
  created () {
    setTitle(this.$t('names.title'))
    setMeta('keywords', 'keywords', this.$t('names.keywords'))
    setMeta('description', 'description', this.$t('names.description'))
  },
  setup () {
    const store = useStore()
    return {
      names: computed(() => store.state.names)
    }
  },
  data () {
  },
  methods: {
    setName (name) {
      this.$store.commit('updateName', name)
      this.$store.commit('updateManual', 'false')
    }
  }
}
</script>
<style scoped>
  @import "../assets/css/Shared.css";
  @import "../assets/css/Names.css";
</style>
