import { createStore } from 'vuex'

export default createStore({
  state: {
    request: null,
    gender: 'male',
    length: '2',
    lastName: '', // 起名解名时的姓
    theLastName: '', // 百家姓时的姓的详细解释
    lastNames: null,
    firstName: '',
    birthday: new Date(),
    end: new Date(),
    names: null,
    name: null,
    jieMing: null,
    dictionaries: null,
    analyzeResult: null,
    history: null,
    zodiac: null
  },
  getters: {
    gender: (state) => {
      return state.gender
    },
    length: (state) => {
      return state.length
    },
    lastName: (state) => {
      return state.lastName
    },
    jieMing: (state) => {
      return state.jieMing
    },
    history: (state) => {
      return state.history
    },
    theLastName: (state) => {
      return state.theLastName
    },
    zodiac: (state) => {
      return state.zodiac
    },
    lastNames: (state) => {
      return state.lastNames
    }
  },
  mutations: {
    updateGender (state, gender) {
      state.gender = gender
    },
    updateLastName (state, lastName) {
      state.lastName = lastName
    },
    updateLength (state, length) {
      state.length = length
    },
    updateBirthday (state, birthday) {
      state.birthday = birthday
    },
    updateNames (state, names) {
      state.names = names
    },
    updateName (state, name) {
      state.name = name
    },
    updateRequest (state, request) {
      state.request = request
    },
    updateDictionaries (state, dictionaries) {
      state.dictionaries = dictionaries
    },
    updateAnalyzeResult (state, analyzeResult) {
      state.analyzeResult = analyzeResult
    },
    updateFirstName (state, firstName) {
      state.firstName = firstName
    },
    updateJieMing (state, jieMing) {
      state.jieMing = jieMing
    },
    updateHistory (state, history) {
      state.history = history
    },
    updateZodiac (state, zodiac) {
      state.zodiac = zodiac
    },
    updateTheLastName (state, theLastName) {
      state.theLastName = theLastName
    },
    updateLastNames (state, lastNames) {
      state.lastNames = lastNames
    }
  },
  actions: {
  },
  modules: {
  }
})
