<template>
  <div class="flex-items-center" v-if="this.name">
    <p class="title">{{$t('name.name-fenxi')}}</p>
    <p class="section">{{ this.lastName }}{{ this.name.Name }}</p>
    <label class="label-title">{{$t('name.score')}}:</label>
    <label style="color: #2ea44f; font-size: 1.143rem; font-weight: bold">{{this.name.Score}}</label>分
    <p/>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('common.bazi')}}:</label>
      <div v-if="this.name.ScoreDetail.xiYongShenAlias === '利'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.xiyongshen-good')}}</title>
        </svg>
        <label class="label-value">{{$t('names.xiyongshen-good')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.xiyongshen-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.xiyongshen-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.bazi-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('common.shengxiao')}}:</label>
      <div v-if="this.name.ScoreDetail.shengXiaoAlias === '利'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.shengxiao-good')}}</title>
        </svg>
        <label class="label-value">{{$t('names.shengxiao-good')}}</label>
      </div>
      <div v-else-if="this.name.ScoreDetail.shengXiaoAlias === '弊'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.shengxiao-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.shengxiao-bad')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
          <title>{{$t('names.shengxiao-non')}}</title>
        </svg>
        <label class="label-value">{{$t('names.shengxiao-non')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.shengxiao-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('common.pinge')}}:</label>
      <div v-if="this.name.ScoreDetail.pingZeAlias === '异平仄'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.fayin-good')}}</title>
        </svg>
        <label class="label-value">{{$t('names.fayin-good')}}</label>
      </div>
      <div v-else-if="this.name.ScoreDetail.pingZeAlias === '同声调'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.fayin-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.fayin-bad')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
          <title>{{$t('names.fayin-part-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.fayin-part-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.pinge-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('common.gender')}}:</label>
      <div v-if="this.name.ScoreDetail.genderAlias !== '有'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.gender-good')}}</title>
        </svg>
        <label class="label-value">{{$t('names.gender-good')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.gender-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.gender-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.gender-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('names.stroke')}}:</label>
      <div v-if="this.name.ScoreDetail.strokeAlias === '简单'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.stroke-good')}}</title>
        </svg>
        <label class="label-value">{{$t('names.stroke-good')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.stroke-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.stroke-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.stroke-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('names.meaning')}}:</label>
      <div v-if="this.name.ScoreDetail.meaningAlias !== '有'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.meaning-good')}}</title>
        </svg>
        <label class="label-value">{{$t('names.meaning-good')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.meaning-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.meaning-bad')}}</label>
      </div>
    </div>
<!--双音叠韵-->
    <div>
      <label class="label-title">{{$t('name.meaning-notes')}}</label><router-link :to="{name: 'DownloadPage'}"> {{ $t('names.download-tip') }}</router-link>
    </div>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('names.yinyun')}}:</label>
      <div v-if="this.name.ScoreDetail.dieYunAlias === '不叠韵'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.yinyun-good')}}</title>
        </svg>
        <label class="label-value">{{$t('names.yinyun-good')}}</label>
      </div>
      <div v-else-if="this.name.ScoreDetail.dieYunAlias === '全叠韵'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.yinyun-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.yinyun-bad')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
          <title>{{$t('names.yinyun-part-bad')}}</title>
        </svg>
        <label class="label-value">{{$t('names.yinyun-part-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.yinyun-notes')}}</label>
    <br/>
    <!--    字义解释-->
    <p class="title">{{$t('name.word-explan')}}</p>
    <div v-for="(word, i) in this.lastName + this.name.Name " :index="i" :key="i">
      <p/>
      <label class="label-value">{{word}} &nbsp;&nbsp;</label>
      <div v-if="this.dictionaries && this.dictionaries[word]">
        <p/>
        <label class="label-title">{{$t('common.pinyin')}}:</label><label class="label-value">{{this.dictionaries[word].pinyin}}</label>
        <label class="label-title">{{$t('common.wuxing')}}:</label><label class="label-value">{{this.dictionaries[word].wuxing}}</label>
        <p/>
        <label class="label-title">{{this.dictionaries[word].explanation}}</label>
      </div>
      <hr/>
    </div>
<!--    诗词歌赋-->
    <div v-if="this.analyzeResult && (this.analyzeResult.ci.length + this.analyzeResult.shiJing.length + this.analyzeResult.chengYu.length + this.analyzeResult.chengYu2.length) > 0">
      <p class="title">{{$t('common.shicigefu')}}</p>
      <div v-if="(this.analyzeResult.chengYu.length + this.analyzeResult.chengYu2.length) > 0">
        <p class="section">{{$t('common.chengyu')}}</p>
        <div v-for="(item, i) in this.analyzeResult.chengYu.concat(this.analyzeResult.chengYu2)" :index="i" :key="i">
          <p/>
          <label class="label-value">{{item.name}}</label>
          <label class="label-title">{{item.explanation}}</label>
          <hr/>
        </div>
      </div>
      <div v-if="this.analyzeResult.ci.length > 0">
        <p class="section">{{$t('common.songci')}}</p>
        <div v-for="(item, i) in this.analyzeResult.ci" :index="i" :key="i">
          <p/>
          <label class="label-value">{{item.title +' - ' +item.author}}</label>
          <label class="label-title">{{item.content}}</label>
          <hr/>
        </div>
      </div>
      <div v-if="this.analyzeResult.shiJing.length > 0">
        <p class="section">{{$t('common.shijing')}}</p>
        <div v-for="(item, i) in this.analyzeResult.shiJing" :index="i" :key="i">
          <p/>
          <label class="label-value">{{item.title}}</label>
          <label class="label-title">{{item.content}}</label>
          <hr/>
        </div>
      </div>
      <div v-if="this.analyzeResult.shi.length > 0">
        <p class="section">{{$t('common.tangshi')}}</p>
        <div v-for="(item, i) in this.analyzeResult.shi" :index="i" :key="i">
          <p/>
          <label class="label-value">{{item.title+' - ' +item.author}}</label>
          <label class="label-title">{{item.content}}</label>
          <hr/>
        </div>
      </div>
    </div>
    <p/>
    <router-link :to="{name: 'DownloadPage'}"> {{ $t('names.download-tip') }}</router-link>
  </div>
</template>

<script>
import { setMeta, setTitle } from '../utils/setMeta'
import axios from 'axios'
import { useStore } from 'vuex'
import { computed } from 'vue'
const httpHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'api-version': 110,
  Authorization:
    '$X3t8&Rmm8+n0IATWO125^KK78aQ*p4&I2Y&s_9O8TGnH5g#SVb9l54&?75USS3u'
}
export default {
  name: 'NamePage',
  created () {
    setTitle(this.$t('names.title'))
    setMeta('keywords', 'keywords', this.$t('names.keywords'))
    setMeta('description', 'description', this.$t('names.description'))
    this.analyzeName(this.request)
  },
  setup () {
    const store = useStore()
    return {
      name: computed(() => store.state.name),
      lastName: computed(() => store.state.lastName),
      request: computed(() => store.state.request),
      dictionaries: computed(() => store.state.dictionaries),
      analyzeResult: computed(() => store.state.analyzeResult),
      relatedNames: computed(() => store.state.relatedNames)
    }
  },
  methods: {
    analyzeName (payload) {
      payload = JSON.parse(payload)
      if (!payload.firstName) {
        payload.firstName = this.name.Name
      }
      axios.post(process.env.VUE_APP_BASE_URL + '/analyze-name?manual=false', JSON.stringify(payload), { headers: httpHeader })
        .then((res) => {
          if (res.status === 200) {
            this.relatedNames = res.data.relatedNames
            this.dictionaries = res.data.dictionaries
            this.$store.commit('updateDictionaries', res.data.dictionaries)
            this.$store.commit('updateAnalyzeResult', res.data)
            // this.tongYinZi = res.json().tongYinZi
          }
        })
        .catch((error) => {
          // error.response.status Check status code
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
  @import "../assets/css/Shared.css";
</style>
