import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'

// import hk from './locales/hk.json'
// import tw from './locales/tw.json'
import zh from './locales/zh.json'
const i18n = createI18n({
  legacy: false,
  locale: 'zh',
  messages: {
    zh
  }
})
const app = createApp(App)
app.use(i18n)
app.use(store)
app.use(router)
app.mount('#app')
