import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import AboutUs from '../views/about/AboutUs'
import DownloadPage from '../views/DownloadPage'
import NamesPage from '../views/NamesPage'
import NamePage from '../views/NamePage'
import JieMingPage from '../views/JieMingPage'
import HistoryPage from '../views/HistoryPage'
import AnalyzePage from '../views/AnalyzePage'
import PrivacyPage from '../views/about/PrivacyPage'
import TermsPage from '../views/about/TermsPage'

import ZodiacsPage from '../views/zodiac/ZodiacsPage'
import ZodiacPage from '../views/zodiac/ZodiacPage'
import LastNames from '../views/lastname/LastNames'
import LastName from '../views/lastname/LastName'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/download',
    name: 'DownloadPage',
    component: DownloadPage
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: PrivacyPage
  },
  {
    path: '/terms',
    name: 'TermsPage',
    component: TermsPage
  },
  {
    path: '/names',
    name: 'NamesPage',
    component: NamesPage
  },
  {
    path: '/lastnames',
    name: 'LastNames',
    component: LastNames
  },
  {
    path: '/lastname/:lastname',
    name: 'LastName',
    component: LastName
  },
  {
    path: '/name',
    name: 'NamePage',
    component: NamePage
  },
  {
    path: '/analyze',
    name: 'AnalyzePage',
    component: AnalyzePage
  },
  {
    path: '/jieming',
    name: 'JieMingPage',
    component: JieMingPage
  },
  {
    path: '/history',
    name: 'HistoryPage',
    component: HistoryPage
  },
  {
    path: '/zodiacs',
    name: 'ZodiacsPage',
    component: ZodiacsPage
  },
  {
    path: '/zodiac/:zodiac',
    name: 'ZodiacPage',
    component: ZodiacPage
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
