<template>
  <div class="flex-items-center">
    <p class="title">{{ $t(this.$route.params.zodiac + '.title') }}</p>
    <p>{{ $t('zodiac.yi-words') }}:{{this.zodiac.宜}}</p>
    <p>{{ $t('zodiac.yi-explain') }}</p>
    <label class="label-title" style="white-space: pre-wrap;">{{this.zodiac.good_explain}}</label>
    <hr/>
    <p>{{ $t('zodiac.ji-words') }}:{{this.zodiac.忌}}</p>
    <p>{{ $t('zodiac.ji-explain') }}</p>
    <label class="label-title" style="white-space: pre-wrap;">{{this.zodiac.bad_explain}}</label>
    <hr/>
  </div>
</template>

<script>
import { setMeta, setTitle } from '../../utils/setMeta'
import axios from 'axios'
import { useStore } from 'vuex'
import { computed } from 'vue'
const httpHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'api-version': 110,
  Authorization:
      '$X3t8&Rmm8+n0IATWO125^KK78aQ*p4&I2Y&s_9O8TGnH5g#SVb9l54&?75USS3u'
}
export default {
  name: 'ZodiacPage',
  created () {
    setTitle(this.$t(this.$route.params.zodiac + '.title'))
    setMeta('keywords', 'keywords', this.$t(this.$route.params.zodiac + '.keywords'))
    setMeta('description', 'description', this.$t(this.$route.params.zodiac + '.description'))
    this.fetchZodiac()
  },
  setup () {
    const store = useStore()
    return {
      zodiac: computed(() => store.state.zodiac)
    }
  },
  methods: {
    fetchZodiac () {
      axios.get(process.env.VUE_APP_BASE_URL + '/zodiac/' + this.$route.params.zodiac, { headers: httpHeader })
        .then((res) => {
          if (res.status === 200) {
            this.$store.commit('updateZodiac', res.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
  .flex-items-center {
    align-items: center !important;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI Variable,Segoe UI,system-ui,ui-sans-serif,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    line-height: 1.5;
    padding-bottom: 0.571rem !important;
    padding-top: 0.571rem !important;
    text-align: left;
  }
  .title {
    font-size: 1.714rem;
    font-weight: 400;
    margin-bottom: 0.571rem;
  }
</style>
