<template>

  <ul class="footer-ul">
    <li class="footer-li">{{ $t('footer.copy') }}</li>
    <li class="footer-li">
      <router-link :to="{ name: 'ZodiacsPage' }"> {{ $t('footer.zodiac') }}</router-link>
    </li>
    <li class="footer-li">
      <router-link :to="{ name: 'LastNames' }"> {{ $t('footer.lastnames') }}</router-link>
    </li>
    <li class="footer-li">
      <router-link :to="{ name: 'TermsPage' }"> {{ $t('footer.terms') }}</router-link>
    </li>
    <li class="footer-li">
      <router-link :to="{ name: 'PrivacyPage' }"> {{ $t('footer.privacy') }}</router-link>
    </li>
    <li class="footer-li"> <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033670"
        target="_blank">{{ $t('footer.gongan') }}</a></li>
    <li class="footer-li"> <a href="https://beian.miit.gov.cn/" target="_blank">{{ $t('footer.icp') }}</a></li>
    <li class="footer-li"> <a href="https://youkuang.ltd">youkuang财务系统</a></li>
    <li class="footer-li">
      <router-link style="color:white; font-size: 8px" :to="{ name: 'HistoryPage' }"> {{ $t('footer.history') }}
      </router-link>
    </li>
  </ul>

</template>

<script>
export default {
  name: 'FooterPage',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../assets/css/Footer.css";
</style>
