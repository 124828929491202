<template>
  <div class="flex-items-center" v-if="this.jieMing">
    <!--    基本信息-->
    <p class="title">{{$t('names.baseinfo')}}</p>
    <p class="section">{{ this.jieMing.request.lastName }}{{ this.jieMing.request.firstName }}</p>
    <label class="label-title">{{$t('name.score')}}:</label>
    <label style="color: #2ea44f; font-size: 1.143rem; font-weight: bold">{{this.jieMing.score}}</label>分<br/>
    <label class="label-title">{{$t('common.last-name')}}:</label><label class="label-value">{{this.jieMing.request.lastName}}</label>
    <label class="label-title">{{$t('common.gender')}}:</label><label class="label-value">{{this.jieMing.request.gender}}</label>
    <label class="label-title">{{$t('common.shengxiao')}}:</label><label class="label-value">{{this.jieMing.solar.animal}}</label>
    <label class="label-title">{{$t('common.xingzuo')}}:</label><label class="label-value">{{this.jieMing.solar.constellation}}</label>
    <label class="label-title">{{$t('common.birthday')}}:</label><label class="label-value">{{this.jieMing.solar.year}}{{$t('common.year')}}{{this.jieMing.solar.month}}{{$t('common.month')}}{{this.jieMing.solar.day}}{{$t('common.day')}} {{this.jieMing.solar.hour}}{{$t('common.hour')}}</label>
    <label class="label-title">{{$t('common.lunar-birthday')}}:</label><label class="label-value">{{this.jieMing.lunar.year_alias}}{{$t('common.year')}}{{this.jieMing.lunar.month_alias}}{{this.jieMing.lunar.day_alias}}{{$t('common.day')}} {{this.jieMing.ganzhi.hour.split('')[1]}}{{$t('common.hour')}}</label>
    <p class="section">{{$t('common.bazi')}}</p>
    <label class="label-title">{{$t('common.tiangan')}}:</label><label class="label-value">{{this.jieMing.ganzhi.year.split('')[0]}} {{this.jieMing.ganzhi.month.split('')[0]}} {{this.jieMing.ganzhi.day.split('')[0]}} {{this.jieMing.ganzhi.hour.split('')[0]}}</label>
    <label class="label-title">{{$t('common.dizhi')}}:</label><label class="label-value">{{this.jieMing.ganzhi.year.split('')[1]}} {{this.jieMing.ganzhi.month.split('')[1]}} {{this.jieMing.ganzhi.day.split('')[1]}} {{this.jieMing.ganzhi.hour.split('')[1]}}</label>
    <p class="section">{{$t('names.wuxing-count')}}</p>
    <label class="label-title">{{$t('common.jin')}}:</label><label class="label-value">{{this.jieMing.wuxing.jin}}</label>
    <label class="label-title">{{$t('common.mu')}}:</label><label class="label-value">{{this.jieMing.wuxing.mu}}</label>
    <label class="label-title">{{$t('common.shui')}}:</label><label class="label-value">{{this.jieMing.wuxing.shui}}</label>
    <label class="label-title">{{$t('common.huo')}}:</label><label class="label-value">{{this.jieMing.wuxing.huo}}</label>
    <label class="label-title">{{$t('common.tu')}}:</label><label class="label-value">{{this.jieMing.wuxing.tu}}</label>
    <p/>
    <label class="label-value">
      {{ (this.jieMing.wuxing.jin > 0 && this.jieMing.wuxing.mu > 0 && this.jieMing.wuxing.shui > 0 && this.jieMing.wuxing.huo > 0 && this.jieMing.wuxing.tu > 0) ? $t('names.wuxing-all'): $t('names.wuxing-miss')+ (
      (this.jieMing.wuxing.jin === 0 ? '金' : '') +
      (this.jieMing.wuxing.mu === 0 ? '木' : '') +
      (this.jieMing.wuxing.shui === 0 ? '水' : '') +
      (this.jieMing.wuxing.huo === 0 ? '火' : '') +
      (this.jieMing.wuxing.tu === 0 ? '土' : '')
      )
      }}
    </label>
    <p/>
    <label class="label-value">
      {{$t('names.wuxing-notes')}}
    </label>
    <p class="section">{{$t('names.wuxing-value')}}</p>
    <label class="label-title">{{$t('common.jin')}}:</label><label class="label-value">{{new Number(this.jieMing.wuxing.jinPoint).toFixed(2)}}</label>
    <label class="label-title">{{$t('common.mu')}}:</label><label class="label-value">{{new Number(this.jieMing.wuxing.muPoint).toFixed(2)}}</label>
    <label class="label-title">{{$t('common.shui')}}:</label><label class="label-value">{{new Number(this.jieMing.wuxing.shuiPoint).toFixed(2)}}</label>
    <label class="label-title">{{$t('common.huo')}}:</label><label class="label-value">{{new Number(this.jieMing.wuxing.huoPoint).toFixed(2)}}</label>
    <label class="label-title">{{$t('common.tu')}}:</label><label class="label-value">{{new Number(this.jieMing.wuxing.tuPoint).toFixed(2)}}</label>
    <p class="section">{{$t('common.xiyongshen')}}</p>
    <label class="label-title">{{$t('common.xiyongshen')}}:</label><label class="label-value">{{this.jieMing.wuxing.xiYongShen}}</label>
    <p/>
    <label class="label-value">{{this.jieMing.wuxing.xiYongShenComment}}</label>
    <p class="section">{{$t('names.shenxiao-prefer')}}</p>
    <label class="label-title">{{$t('common.yi')}}:</label><label class="label-value">{{this.jieMing.shengXiaoPreference.宜}}</label>
    <p/>
    <label class="label-value" style="white-space: pre-wrap;">
      {{this.jieMing.shengXiaoPreference.good_explain}}
    </label>
    <p/>
    <label class="label-title">{{$t('common.ji')}}:</label><label class="label-value" style="color: red">{{this.jieMing.shengXiaoPreference.忌}}</label>
    <p/>
    <label class="label-value" style="white-space: pre-wrap;">
      {{this.jieMing.shengXiaoPreference.bad_explain}}
    </label>
<!--    玄宗分析-->
    <p class="section">{{$t('names.title-fenxi')}}</p>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('common.bazi')}}:</label>
      <div v-if="this.jieMing.scoreDetail.xiYongShenAlias === '利'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.xiyongshen-good')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.xiyongshen-good')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.xiyongshen-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.xiyongshen-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.bazi-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('common.shengxiao')}}:</label>
      <div v-if="this.jieMing.scoreDetail.shengXiaoAlias === '利'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.shengxiao-good')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.shengxiao-good')}}</label>
      </div>
      <div v-else-if="this.jieMing.scoreDetail.shengXiaoAlias === '弊'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.shengxiao-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.shengxiao-bad')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
          <title>{{$t('names.shengxiao-non')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.shengxiao-non')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.shengxiao-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('common.pinge')}}:</label>
      <div v-if="this.jieMing.scoreDetail.pingZeAlias === '异平仄'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.fayin-good')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.fayin-good')}}</label>
      </div>
      <div v-else-if="this.jieMing.scoreDetail.pingZeAlias === '同声调'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.fayin-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.fayin-bad')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
          <title>{{$t('names.fayin-part-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.fayin-part-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.pinge-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('common.gender')}}:</label>
      <div v-if="this.jieMing.scoreDetail.genderAlias !== '有'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.gender-good')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.gender-good')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.gender-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.gender-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.gender-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('names.stroke')}}:</label>
      <div v-if="this.jieMing.scoreDetail.strokeAlias === '简单'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.stroke-good')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.stroke-good')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.stroke-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.stroke-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.stroke-notes')}}</label>
    <div style="display: flex !important;">
      <label class="label-value">{{$t('names.meaning')}}:</label>
      <div v-if="this.jieMing.scoreDetail.meaningAlias !== '有'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.meaning-good')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.meaning-good')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.meaning-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.meaning-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.meaning-notes')}}</label>
<!--    双声叠韵-->
    <div style="display: flex !important;">
      <label class="label-value">{{$t('names.yinyun')}}:</label>
      <div v-if="this.jieMing.scoreDetail.dieYunAlias === '不叠韵'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
          <title>{{$t('names.yinyun-good')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.yinyun-good')}}</label>
      </div>
      <div v-else-if="this.jieMing.scoreDetail.dieYunAlias === '全叠韵'">
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
          <title>{{$t('names.yinyun-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.yinyun-bad')}}</label>
      </div>
      <div v-else>
        <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
          <title>{{$t('names.yinyun-part-bad')}}</title>
        </svg>
        <label class="label-value" style="white-space: pre-wrap;">{{$t('names.yinyun-part-bad')}}</label>
      </div>
    </div>
    <label class="label-title">{{$t('name.yinyun-notes')}}</label>
    <!--    字义解释 -->
    <p class="title">{{$t('name.word-explan')}}</p>
    <div v-for="(word, index) in this.jieMing.request.lastName" :index="index" :key="this.jieMing.request.lastName + this.jieMing.request.firstName + index">
      <p/>
      <label class="label-value">{{word}} &nbsp;&nbsp;</label>
      <div v-if="this.jieMing.dictionaries && this.jieMing.dictionaries[word]">
        <p/>
        <label class="label-title">{{$t('common.pinyin')}}:</label><label class="label-value">{{this.jieMing.dictionaries[word].pinyin}}</label>
        <label class="label-title">{{$t('common.wuxing')}}:</label><label class="label-value">{{this.jieMing.dictionaries[word].wuxing}}</label>
        <p/>
        <label class="label-title" style="white-space: pre-wrap;">{{this.jieMing.dictionaries[word].explanation}}</label>
      </div>
      <hr/>
    </div>
    <div v-for="(word, index) in this.jieMing.request.firstName " :index="index" :key="this.jieMing.request.lastName + this.jieMing.request.firstName + index">
      <p/>
      <label class="label-value">{{word}} &nbsp;&nbsp;</label>
      <div v-if="this.jieMing.dictionaries && this.jieMing.dictionaries[word]">
        <p/>
        <label class="label-title">{{$t('common.pinyin')}}:</label><label class="label-value">{{this.jieMing.dictionaries[word].pinyin}}</label>
        <label class="label-title">{{$t('common.wuxing')}}:</label><label class="label-value">{{this.jieMing.dictionaries[word].wuxing}}</label>
        <div v-if="this.jieMing.tongYinZi[index].length < 2">
          <label class="label-title">{{$t('common.tongyinzi')}}:</label><label class="label-value">{{$t('common.no-tongyinzi')}}</label>
        </div>
        <div v-else>
          <label class="label-title">{{$t('common.tongyinzi')}}:</label><label class="label-value">{{this.jieMing.tongYinZi[index].slice(1)}}</label>
        </div>
        <p/>
        <label class="label-title">{{this.jieMing.dictionaries[word].explanation}}</label>
      </div>
      <hr/>
    </div>
    <!--    推荐名字-->
    <p class="title">{{$t('common.tuijianmingzi')}}</p>
    <div v-if="(this.jieMing.relatedNames.length) > 0">
      <div v-for="(item, i) in this.jieMing.relatedNames" :index="i" :key="i">
        {{i+1}}&nbsp;&nbsp;&nbsp;&nbsp;
        <router-link :title="this.jieMing.request.lastName + item.Name + $t('jieming.title')" :to="{ name: 'JieMingPage', query: {firstName:encodeURIComponent(item.Name), lastName: encodeURIComponent(this.jieMing.request.lastName), gender: this.jieMing.request.gender, birthday: this.jieMing.request.birthday, birthdaySeconds: this.jieMing.request.birthdaySeconds, manual: 'false'}}">
          {{ this.jieMing.request.lastName }}{{ item.Name }}
        </router-link>
        &nbsp;&nbsp;&nbsp;&nbsp; <label style="color: #2ea44f; font-size: 1.143rem; font-weight: bold">{{item.Score}}</label>{{$t('common.score')}}
        <p/>
        <div class="fenxi">
          <div style="display: flex !important;">
            <label class="label-title">{{$t('common.bazi')}}:</label>
            <div v-if="item.ScoreDetail.xiYongShenAlias === '利'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
                <title>{{$t('names.xiyongshen-good')}}</title>
              </svg>
            </div>
            <div v-else>
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
                <title>{{$t('names.xiyongshen-bad')}}</title></svg>
            </div>
          </div>
          <div style="display: flex !important;">
            <label class="label-title">{{$t('common.shengxiao')}}:</label>
            <div v-if="item.ScoreDetail.shengXiaoAlias === '利'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
                <title>{{$t('names.shengxiao-good')}}</title>
              </svg>
            </div>
            <div v-else-if="item.ScoreDetail.shengXiaoAlias === '弊'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
                <title>{{$t('names.shengxiao-bad')}}</title>
              </svg>
            </div>
            <div v-else>
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
                <title>{{$t('names.shengxiao-non')}}</title>
              </svg>
            </div>
          </div>
          <div style="display: flex !important;">
            <label class="label-title">{{$t('common.pinge')}}:</label>
            <div v-if="item.ScoreDetail.pingZeAlias === '异平仄'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
                <title>{{$t('names.fayin-good')}}</title>
              </svg>
            </div>
            <div v-else-if="item.ScoreDetail.pingZeAlias === '同声调'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
                <title>{{$t('names.fayin-bad')}}</title>
              </svg>
            </div>
            <div v-else>
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
                <title>{{$t('names.fayin-part-bad')}}</title>
              </svg>
            </div>
          </div>
          <div style="display: flex !important;">
            <label class="label-title">{{$t('common.gender')}}:</label>
            <div v-if="item.ScoreDetail.genderAlias !== '有'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
                <title>{{$t('names.gender-good')}}</title>
              </svg>
            </div>
            <div v-else>
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
                <title>{{$t('names.gender-bad')}}</title>
              </svg>
            </div>
          </div>
          <div style="display: flex !important;">
            <label class="label-title">{{$t('names.stroke')}}:</label>
            <div v-if="item.ScoreDetail.strokeAlias === '简单'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
                <title>{{$t('names.stroke-good')}}</title>
              </svg>
            </div>
            <div v-else>
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
                <title>{{$t('names.stroke-bad')}}</title>
              </svg>
            </div>
          </div>
          <div style="display: flex !important;">
            <label class="label-title">{{$t('names.meaning')}}:</label>
            <div v-if="item.ScoreDetail.meaningAlias !== '有'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
                <title>{{$t('names.meaning-good')}}</title>
              </svg>
            </div>
            <div v-else>
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
                <title>{{$t('names.meaning-bad')}}</title>
              </svg>
            </div>
          </div>
          <div style="display: flex !important;">
            <label class="label-title">{{$t('names.yinyun')}}:</label>
            <div v-if="item.ScoreDetail.dieYunAlias === '不叠韵'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#0e932e" d="M512 85.333333C276.48 85.333333 85.333333 276.48 85.333333 512S276.48 938.666667 512 938.666667 938.666667 747.52 938.666667 512 747.52 85.333333 512 85.333333z m205.937778 341.333334L481.28 651.946667c-5.688889 5.688889-12.515556 7.964444-19.342222 7.964444s-13.653333-2.275556-19.342222-7.964444l-136.533334-129.706667c-11.377778-11.377778-11.377778-28.444444-1.137778-39.822222 11.377778-11.377778 28.444444-11.377778 39.822223-1.137778L461.937778 591.644444l216.177778-207.075555c11.377778-11.377778 29.582222-10.24 39.822222 1.137778 11.377778 11.377778 11.377778 29.582222 0 40.96z" />
                <title>{{$t('names.yinyun-good')}}</title>
              </svg>
            </div>
            <div v-else-if="item.ScoreDetail.dieYunAlias === '全叠韵'">
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#d81e06" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m165.4 618.2l-66-0.3L512 563.4l-99.3 118.4-66.1 0.3c-4.4 0-8-3.5-8-8 0-1.9 0.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1 0.3L512 464.6l99.3-118.4 66-0.3c4.4 0 8 3.5 8 8 0 1.9-0.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" />
                <title>{{$t('names.yinyun-bad')}}</title>
              </svg>
            </div>
            <div v-else>
              <svg class="icon" width="16" height="16" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path fill="#f4ea2a" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296z m32 440c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48z" />
                <title>{{$t('names.yinyun-part-bad')}}</title>
              </svg>
            </div>
          </div>
        </div>
        <hr/>
      </div>
      <div>
        <router-link :to="{name: 'DownloadPage'}"> {{ $t('names.download-tip') }}</router-link>
      </div>
    </div>
    <div v-else >
      <p >{{$t('common.no-tuijianmingzi')}}</p>
    </div>
    <!--    诗词歌赋-->
    <div v-if="this.jieMing && (this.jieMing.ci.length + this.jieMing.shiJing.length + this.jieMing.chengYu.length + this.jieMing.chengYu2.length) > 0">
      <p class="title">{{$t('common.shicigefu')}}</p>
      <div v-if="(this.jieMing.chengYu.length + this.jieMing.chengYu2.length) > 0">
        <p class="section">{{$t('common.chengyu')}}</p>
        <div v-for="(item, i) in this.jieMing.chengYu.concat(this.jieMing.chengYu2)" :index="i" :key="i">
          <p/>
          <label class="label-value">{{item.name}}</label>
          <label class="label-title">{{item.explanation}}</label>
          <hr/>
        </div>
      </div>
      <div v-if="this.jieMing.ci.length > 0">
        <p class="section">{{$t('common.songci')}}</p>
        <div v-for="(item, i) in this.jieMing.ci" :index="i" :key="i">
          <p/>
          <label class="label-value">{{item.title +' - ' +item.author}}</label>
          <label class="label-title">{{item.content}}</label>
          <hr/>
        </div>
      </div>
      <div v-if="this.jieMing.shiJing.length > 0">
        <p class="section">{{$t('common.shijing')}}</p>
        <div v-for="(item, i) in this.jieMing.shiJing" :index="i" :key="i">
          <p/>
          <label class="label-value">{{item.title}}</label>
          <label class="label-title">{{item.content}}</label>
          <hr/>
        </div>
      </div>
      <div v-if="this.jieMing.shi.length > 0">
        <p class="section">{{$t('common.tangshi')}}</p>
        <div v-for="(item, i) in this.jieMing.shi" :index="i" :key="i">
          <p/>
          <label class="label-value">{{item.title+' - ' +item.author}}</label>
          <label class="label-title">{{item.content}}</label>
          <hr/>
        </div>
      </div>
    </div>
    <p/>
    <router-link :to="{name: 'DownloadPage'}"> {{ $t('names.download-tip') }}</router-link>
  </div>
</template>

<script>
import { setMeta, setTitle } from '../utils/setMeta'
import { useStore } from 'vuex'
import { computed } from 'vue'
import axios from 'axios'
const httpHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'api-version': 110,
  Authorization:
    '$X3t8&Rmm8+n0IATWO125^KK78aQ*p4&I2Y&s_9O8TGnH5g#SVb9l54&?75USS3u'
}
export default {
  name: 'JieMingPage',
  created () {
    if (this.$route.query.lastName && this.$route.query.firstName) {
      setTitle(decodeURIComponent(this.$route.query.lastName) + decodeURIComponent(this.$route.query.firstName) + ' ' + this.$t('jieming.title'))
      setMeta('keywords', 'keywords', decodeURIComponent(this.$route.query.lastName) + decodeURIComponent(this.$route.query.firstName) + ', ' + this.$t('jieming.keywords'))
      setMeta('description', 'description', decodeURIComponent(this.$route.query.lastName) + decodeURIComponent(this.$route.query.firstName) + '测名结果. ' + this.$t('jieming.description'))
    } else {
      setTitle(this.$t('jieming.title'))
      setMeta('keywords', 'keywords', this.$t('jieming.keywords'))
      setMeta('description', 'description', this.$t('jieming.description'))
    }
    this.fetchName()
  },
  watch: {
    $route (to, from) {
      // 对路由变化作出响应...
      if (to.path === from.path && to.fullPath !== from.fullPath) {
        this.fetchName()
      }
    }
  },
  setup () {
    const store = useStore()
    return {
      jieMing: computed(() => store.state.jieMing)
    }
  },
  methods: {
    fetchName () {
      if (!(this.$route.query.gender && this.$route.query.lastName && this.$route.query.firstName) && (this.$route.query.birthday || this.$route.query.birthdaySeconds)) {
        this.$router.push({ name: 'AnalyzePage', query: { gender: this.query.gender, birthday: this.$route.query.birthday, birthdaySeconds: this.$route.query.birthdaySeconds, lastName: this.query.lastName, firstName: this.query.firstName } })
        return
      }
      const gender = this.$route.query.gender
      const birthday = this.$route.query.birthday
      const lastName = decodeURIComponent(this.$route.query.lastName)
      const firstName = decodeURIComponent(this.$route.query.firstName)
      let manual = 'false'
      if (this.$route.query.manual) {
        manual = this.$route.query.manual
      }
      const body = JSON.stringify({
        gender,
        birthday,
        birthdaySeconds: parseInt(this.$route.query.birthdaySeconds),
        lastName,
        firstName,
        specifyAt: 0,
        specify: '',
        deviceUniqueId: '',
        appVersion: '',
        appStore: 'webpage',
        phoneBrand: '',
        userId: ''
      })
      this.$store.commit('updateRequest', body)
      // console.info(body)
      axios.post(process.env.VUE_APP_BASE_URL + '/analyze-name?manual=' + manual, body, { headers: httpHeader })
        .then((res) => {
          if (res.status === 200) {
            this.$store.commit('updateJieMing', res.data)
          }
        })
        .catch((error) => {
          // error.response.status Check status code
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
  @import "../assets/css/Shared.css";
</style>
