<template>
  <HeaderPage />
  <router-view id="router-view" />
  <FooterPage/>
</template>

<script>
import HeaderPage from './components/HeaderPage'
import FooterPage from './components/FooterPage'

export default {
  name: 'App',
  components: {
    HeaderPage, FooterPage
  }
}

</script>

<style>
#app {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI Variable,Segoe UI,system-ui,ui-sans-serif,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    font-size: 14px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    text-align: left;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}
</style>
