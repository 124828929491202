<template>
  <div class="flex-items-center" v-if="this.history">
<!--    解名历史-->
    <br/>
    <label class="title">{{$t('history.names')}}</label><router-link :to="{name: 'DownloadPage'}"> {{ $t('names.download-tip') }}</router-link>
    <br/>
    <div v-for="(item, i) in this.history" :index="i" :key="i">
      {{i+1}}&nbsp;&nbsp;&nbsp;&nbsp;
      <router-link :title="item.lastName + item.firstName + $t('jieming.title')" :to="{ name: 'JieMingPage', query: {firstName:item.firstName, lastName: item.lastName, gender: item.gender, birthday: item.birthday, manual: 'false'}}">
        {{ item.lastName }}{{ item.firstName }}
      </router-link>
      <hr/>
    </div>
    <p/>
    <router-link :to="{name: 'DownloadPage'}"> {{ $t('names.download-tip') }}</router-link>
  </div>
</template>

<script>
import { setMeta, setTitle } from '../utils/setMeta'
import { useStore } from 'vuex'
import { computed } from 'vue'
import axios from 'axios'
const httpHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'api-version': 110,
  Authorization:
    '$X3t8&Rmm8+n0IATWO125^KK78aQ*p4&I2Y&s_9O8TGnH5g#SVb9l54&?75USS3u'
}
export default {
  name: 'HistoryPage',
  created () {
    setTitle(this.$t('history.title'))
    setMeta('keywords', 'keywords', this.$t('history.keywords'))
    setMeta('description', 'description', this.$t('history.description'))
    this.fetchHistory()
  },
  setup () {
    const store = useStore()
    return {
      history: computed(() => store.state.history)
    }
  },
  data () {
  },
  methods: {
    fetchHistory () {
      axios.get(process.env.VUE_APP_BASE_URL + '/analyze-history', { headers: httpHeader })
        .then((res) => {
          if (res.status === 200) {
            this.$store.commit('updateHistory', res.data)
          }
        })
        .catch((error) => {
          // error.response.status Check status code
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
  @import "../assets/css/Shared.css";
  .title {
    font-size: 1.714rem;
    font-weight: 400;
    margin-bottom: 0.571rem;
    text-align: left;
  }
</style>
